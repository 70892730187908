.custom-header {
    padding: 10px !important;
}

.custom-header-color {
    background-color: #f2711c!important;
    padding: 10px;
}

.custom-header-color > h4 {
    color: white !important;
}

.custom-header-gray {
    background-color: #383838!important;
}

.custom-header-gray > .row > h1 {
    padding: 10px;
    color: white !important;
}

@media print {
    .custom-header {
        padding: 10px !important;
    }
    
    .custom-header-color {
        background-color: #f2711c!important;
        padding: 10px;
    }
    
    .custom-header-color > h4 {
        color: white !important;
    }
    
    .custom-header-gray {
        background-color: #383838!important;
    }
    
    .custom-header-gray > .row > h1 {
        padding: 10px;
        color: white !important;
    }
}