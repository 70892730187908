/* .ui.visible.left.sidebar ~ .pusher {
    width: calc(100% - 260px) !important;
} */

@media (min-width: 500px) {
    .ui.visible.left.sidebar ~ .pusher {
        width: calc(100% - 260px) !important;
    }

}

.radio-absolute {
    position: absolute !important;
    left: 5px;
}

.text-help {
    position: absolute !important;
    right: 0px;
    margin: 0 !important;
}

.shopping-cart {
    position: absolute !important;
    right: 90px;
    margin: 0 !important;
}

.whatsapp-btn {
    position: absolute !important;
    right: 250px;
    margin: 0 !important;
}

@media (max-width: 499px) {
    .shopping-cart {
        right: 40px;
    }
    
    .whatsapp-btn {
        right: 130px;
    }
}

.checkbox-white-label label {
    color: white !important;
}

.filter-container {
    padding-bottom: 5px !important;
    padding-top: 0 !important;
}

.favorites-section {
    width: 100%;
    margin: 0 !important;
    max-height: 150px;
    overflow: auto;
    scrollbar-color: red yellow;
}

.favorites-section::-webkit-scrollbar {
    background: #ffc58a!important;
    border-radius: 5px;
}

.favorites-section::-webkit-scrollbar-thumb{
    background-color: #f2711c; 
}

::-webkit-scrollbar {
    border-radius: 5px;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

.zoom-hover {
    transition: transform .2s;
}

.zoom-hover:hover {
    transform: scale(2);
}

.checkbox-view-current {
    font-size: larger !important;
    position: absolute !important;
}